import { createBrowserRouter } from "react-router-dom";
import Layout from "../Componets/Layout";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Reports from "../Pages/Reports";
import ProductsTable from "../Pages/Products/Products/Table";
import ProductsForm from "../Pages/Products/Products/Form";
import InventoryExpiryTable from "../Pages/Inventory/Expiry/Table";
import InventoryExpiryForm from "../Pages/Inventory/Expiry/Form";
import InventoryStockTable from "../Pages/Inventory/Stock/Table";
import InventoryStockForm from "../Pages/Inventory/Stock/Form";
import InventoryTransferTable from "../Pages/Inventory/Transfer/Table";
import InventoryTransferForm from "../Pages/Inventory/Transfer/Form";
import InventoryReceivedForm from "../Pages/Inventory/Received/Form";
import InventoryReceivedTable from "../Pages/Inventory/Received/Table";
import ExpensesTable from "../Pages/Expenses/Expenses/Table";
import ExpensesForm from "../Pages/Expenses/Expenses/Form";
import PurchasesOrderForm from "../Pages/Purchases/Order/Form";
import PurchasesOrderTable from "../Pages/Purchases/Order/Table";
import PurchasesReceivedTable from "../Pages/Purchases/Received/Table";
import PurchasesReceivedForm from "../Pages/Purchases/Received/Form";
import PurchasesPaidForm from "../Pages/Purchases/Paid/Form";
import PurchasesPaidTable from "../Pages/Purchases/Paid/Table";
import PurchasesReturnTable from "../Pages/Purchases/Return/Table";
import PurchasesReturnForm from "../Pages/Purchases/Return/Form";
import PurchasesSentTable from "../Pages/Purchases/Sent/Table";
import PurchasesSentForm from "../Pages/Purchases/Sent/Form";
import PurchasesRefundTable from "../Pages/Purchases/Refund/Table";
import PurchasesRefundForm from "../Pages/Purchases/Refund/Form";
import BranchesForm from "../Pages/Settings/Branches/Form";
import BranchesTable from "../Pages/Settings/Branches/Table";
import RolesForm from "../Pages/Settings/Roles/Form";
import RolesTable from "../Pages/Settings/Roles/Table";
import UsersTable from "../Pages/Settings/Users/Table";
import UsersForm from "../Pages/Settings/Users/Form";
import GeneralForm from "../Pages/Settings/General/Form";
import SettingsTable from "../Pages/Settings/Settings/Table";
import ProductunitsTable from "../Pages/Products/Units/Table";
import ProductunitsForm from "../Pages/Products/Units/Form";
import ProductcategoriesTable from "../Pages/Products/Categories/Table";
import ProductcategoriesForm from "../Pages/Products/Categories/Form";
import ProductbrandsTable from "../Pages/Products/Brands/Table";
import ProductbrandsForm from "../Pages/Products/Brands/Form";
import ExpensecategoriesTable from "../Pages/Expenses/Categories/Table";
import ExpensecategoriesForm from "../Pages/Expenses/Categories/Form";
import InventoryBatchTable from "../Pages/Inventory/Batch/Table";
import InventoryBatchForm from "../Pages/Inventory/Batch/Form";
import SalesOrderTable from "../Pages/Sales/Invoices/Table";
import SalesOrderForm from "../Pages/Sales/Invoices/Form";
// import SalesReceivedForm from "../Pages/Sales/Received/Form";
// import SalesReceivedTable from "../Pages/Sales/Received/Table";
// import SalesPaidTable from "../Pages/Sales/Paid/Table";
// import SalesPaidForm from "../Pages/Sales/Paid/Form";
// import SalesRefundTable from "../Pages/Sales/Refund/Table";
// import SalesRefundForm from "../Pages/Sales/Refund/Form";
// import SalesReturnTable from "../Pages/Sales/Return/Table";
// import SalesReturnForm from "../Pages/Sales/Return/Form";
// import PurchasePage from "../Pages/Purchases/Order/View";
import InvoicePage from "../Pages/Sales/Invoices/View";
import BillingView from "../Pages/Billing/View";
import InventoryReport from "../Pages/Reports/View/inventory";
import SalesReport from "../Pages/Reports/View/sales";
import PurchaseReport from "../Pages/Reports/View/purchase";
import ProductReport from "../Pages/Reports/View/products";
import ProductsoldReport from "../Pages/Reports/View/productsold";
import ProductpurchaseReport from "../Pages/Reports/View/productpurchase";
import ExpensesReport from "../Pages/Reports/View/expenses";
import CustomersReport from "../Pages/Reports/View/customers";
import SuppliersReport from "../Pages/Reports/View/suppliers";
// import BarcodePrinter from "../Pages/Purchases/Order/View/index1";
import SigninForm from "../Pages/Signin/Form";
import SignupForm from "../Pages/Signup/Form";
import Page404 from "../Pages/Results/Page404";
import Home from "../Pages/Home";
import CustomersTable from "../Pages/Customers/Customers/Table";
import CustomersForm from "../Pages/Customers/Customers/Form";
import SuppliersTable from "../Pages/Suppliers/Suppliers/Table";
import SuppliersForm from "../Pages/Suppliers/Suppliers/Form";
import BillingForm from "../Pages/Billing/Billing/Form";
import PurchaseView from "../Pages/Purchases/Order/View";
import InventoryView from "../Pages/Inventory/Batch/View";
import SalesReturnsTable from "../Pages/Sales/Returns/Table";
import SalesReturnsForm from "../Pages/Sales/Returns/Form";
import SalesReturnsView from "../Pages/Sales/Returns/View";
import PurchasesReturnsTable from "../Pages/Purchases/Returns/Table";
import PurchasesReturnsForm from "../Pages/Purchases/Returns/Form";
import PurchasesReturnsView from "../Pages/Purchases/Returns/View";

const router = createBrowserRouter([
  {
    path: "*",
    element: <Page404 />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/signin",
    element: <SigninForm />,
  },
  {
    path: "/signup",
    element: <SignupForm />,
  },
  {
    path: "/signout",
    element: "",
  },
  {
    path: "/app",
    element: <Layout />,
    children: [
      {
        path: "/app/dashboard",
        element: <Dashboard />,
      },

      {
        path: "/app/products",
        element: <ProductsTable />,
      },
      {
        path: "/app/products/add",
        element: <ProductsForm />,
      },
      {
        path: "/app/products/edit/:id",
        element: <ProductsForm />,
      },
      {
        path: "/app/products/units",
        element: <ProductunitsTable />,
      },
      {
        path: "/app/products/units/add",
        element: <ProductunitsForm />,
      },
      {
        path: "/app/products/units/edit/:id",
        element: <ProductunitsForm />,
      },
      {
        path: "/app/products/categories",
        element: <ProductcategoriesTable />,
      },
      {
        path: "/app/products/categories/add",
        element: <ProductcategoriesForm />,
      },
      {
        path: "/app/products/categories/edit/:id",
        element: <ProductcategoriesForm />,
      },
      {
        path: "/app/products/brands",
        element: <ProductbrandsTable />,
      },
      {
        path: "/app/products/brands/add",
        element: <ProductbrandsForm />,
      },
      {
        path: "/app/products/brands/edit/:id",
        element: <ProductbrandsForm />,
      },

      {
        path: "/app/inventory",
        element: <InventoryBatchTable />,
      },
      {
        path: "/app/inventory/add",
        element: <InventoryBatchForm />,
      },
      {
        path: "/app/inventory/edit/:id",
        element: <InventoryBatchForm />,
      },
      {
        path: "/app/inventory/view/:id",
        element: <InventoryView />,
      },
      // {
      //   path: "/app/inventory/barcode/:id",
      //   element: <BarcodePrinter />,
      // },

      {
        path: "/app/inventory/stock",
        element: <InventoryStockTable />,
      },
      {
        path: "/app/inventory/stock/add",
        element: <InventoryStockForm />,
      },
      {
        path: "/app/inventory/expiry",
        element: <InventoryExpiryTable />,
      },
      {
        path: "/app/inventory/expiry/add",
        element: <InventoryExpiryForm />,
      },
      {
        path: "/app/inventory/transfer",
        element: <InventoryTransferTable />,
      },
      {
        path: "/app/inventory/transfer/add",
        element: <InventoryTransferForm />,
      },
      {
        path: "/app/inventory/received",
        element: <InventoryReceivedTable />,
      },
      {
        path: "/app/inventory/received/add",
        element: <InventoryReceivedForm />,
      },

      {
        path: "/app/billing",
        element: <SalesOrderTable />,
      },
      {
        path: "/app/billing/add",
        element: <BillingForm />,
      },
      {
        path: "/app/billing/edit/:id",
        element: <SalesOrderForm />,
      },
      {
        path: "/app/billing/view/:id",
        element: <BillingView />,
      },

      {
        path: "/app/sales",
        element: <SalesOrderTable />,
      },
      {
        path: "/app/sales/add",
        element: <SalesOrderForm />,
      },

      {
        path: "/app/sales/order",
        element: <SalesOrderTable />,
      },
      {
        path: "/app/sales/invoices/add",
        element: <SalesOrderForm />,
      },
      {
        path: "/app/sales/invoices/edit/:id",
        element: <SalesOrderForm />,
      },
      {
        path: "/app/sales/invoices/view/:id",
        element: <InvoicePage />,
      },

      {
        path: "/app/sales/returns",
        element: <SalesReturnsTable />,
      },
      {
        path: "/app/sales/returns/add",
        element: <SalesReturnsForm />,
      },
      {
        path: "/app/sales/returns/edit/:id",
        element: <SalesReturnsForm />,
      },
      {
        path: "/app/sales/returns/view/:id",
        element: <SalesReturnsView />,
      },

      // {
      //   path: "/app/sales/received",
      //   element: <SalesReceivedTable />,
      // },
      // {
      //   path: "/app/sales/received/add",
      //   element: <SalesReceivedForm />,
      // },
      // {
      //   path: "/app/sales/paid",
      //   element: <SalesPaidTable />,
      // },
      // {
      //   path: "/app/sales/paid/add",
      //   element: <SalesPaidForm />,
      // },
      // {
      //   path: "/app/sales/return",
      //   element: <SalesReturnTable />,
      // },
      // {
      //   path: "/app/sales/return/add",
      //   element: <SalesReturnForm />,
      // },
      // {
      //   path: "/app/sales/refund",
      //   element: <SalesRefundTable />,
      // },
      // {
      //   path: "/app/sales/refund/add",
      //   element: <SalesRefundForm />,
      // },

      {
        path: "/app/purchases",
        element: <PurchasesOrderTable />,
      },
      {
        path: "/app/purchases/add",
        element: <PurchasesOrderForm />,
      },

      {
        path: "/app/purchases/order",
        element: <PurchasesOrderTable />,
      },
      {
        path: "/app/purchases/orders/add",
        element: <PurchasesOrderForm />,
      },
      {
        path: "/app/purchases/orders/edit/:id",
        element: <PurchasesOrderForm />,
      },
      {
        path: "/app/purchases/orders/view/:id",
        element: <PurchaseView />,
      },

      {
        path: "/app/purchases/returns",
        element: <PurchasesReturnsTable />,
      },
      {
        path: "/app/purchases/returns/add",
        element: <PurchasesReturnsForm />,
      },
      {
        path: "/app/purchases/returns/edit/:id",
        element: <PurchasesReturnsForm />,
      },
      {
        path: "/app/purchases/returns/view/:id",
        element: <PurchasesReturnsView />,
      },

      {
        path: "/app/purchases/received",
        element: <PurchasesReceivedTable />,
      },
      {
        path: "/app/purchases/received/add",
        element: <PurchasesReceivedForm />,
      },
      {
        path: "/app/purchases/paid",
        element: <PurchasesPaidTable />,
      },
      {
        path: "/app/purchases/paid/add",
        element: <PurchasesPaidForm />,
      },
      {
        path: "/app/purchases/return",
        element: <PurchasesReturnTable />,
      },
      {
        path: "/app/purchases/return/add",
        element: <PurchasesReturnForm />,
      },
      {
        path: "/app/purchases/sent",
        element: <PurchasesSentTable />,
      },
      {
        path: "/app/purchases/sent/add",
        element: <PurchasesSentForm />,
      },
      {
        path: "/app/purchases/refund",
        element: <PurchasesRefundTable />,
      },
      {
        path: "/app/purchases/refund/add",
        element: <PurchasesRefundForm />,
      },

      {
        path: "/app/expenses",
        element: <ExpensesTable />,
      },
      {
        path: "/app/expenses/add",
        element: <ExpensesForm />,
      },
      {
        path: "/app/expenses/edit/:id",
        element: <ExpensesForm />,
      },
      {
        path: "/app/expenses/categories",
        element: <ExpensecategoriesTable />,
      },
      {
        path: "/app/expenses/categories/add",
        element: <ExpensecategoriesForm />,
      },
      {
        path: "/app/expenses/categories/edit/:id",
        element: <ExpensecategoriesForm />,
      },

      {
        path: "/app/customers",
        element: <CustomersTable />,
      },
      {
        path: "/app/customers/add",
        element: <CustomersForm />,
      },
      {
        path: "/app/customers/edit/:id",
        element: <CustomersForm />,
      },

      {
        path: "/app/suppliers",
        element: <SuppliersTable />,
      },
      {
        path: "/app/suppliers/add",
        element: <SuppliersForm />,
      },
      {
        path: "/app/suppliers/edit/:id",
        element: <SuppliersForm />,
      },

      {
        path: "/app/reports",
        element: <Reports />,
      },
      {
        path: "/app/reports/products",
        element: <ProductReport />,
      },
      {
        path: "/app/reports/products/sold",
        element: <ProductsoldReport />,
      },
      {
        path: "/app/reports/products/purchase",
        element: <ProductpurchaseReport />,
      },
      {
        path: "/app/reports/inventory",
        element: <InventoryReport />,
      },
      {
        path: "/app/reports/sales",
        element: <SalesReport />,
      },
      {
        path: "/app/reports/purcases",
        element: <PurchaseReport />,
      },
      {
        path: "/app/reports/expenses",
        element: <ExpensesReport />,
      },
      {
        path: "/app/reports/customers",
        element: <CustomersReport />,
      },
      {
        path: "/app/reports/suppliers",
        element: <SuppliersReport />,
      },
      {
        path: "/app/settings",
        element: <SettingsTable />,
      },
      {
        path: "/app/settings/general",
        element: <GeneralForm />,
      },
      {
        path: "/app/settings/users",
        element: <UsersTable />,
      },
      {
        path: "/app/settings/users/add",
        element: <UsersForm />,
      },
      {
        path: "/app/settings/branches",
        element: <BranchesTable />,
      },
      {
        path: "/app/settings/branches/add",
        element: <BranchesForm />,
      },
      {
        path: "/app/settings/branches/edit/:id",
        element: <BranchesForm />,
      },

      {
        path: "/app/settings/branches",
        element: <BranchesTable />,
      },
      {
        path: "/app/settings/roles",
        element: <RolesTable />,
      },
      {
        path: "/app/settings/roles/add",
        element: <RolesForm />,
      },
    ],
  },
]);

export default router;
