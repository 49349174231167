const forms = [
  {
    name: "Purchases Returns",
    className: "bg-white p-4 rounded-md p-8 w-[90%] mx-auto",
    options: [
      // {
      //   name: "Customer",
      //   type: "selectandadd",
      //   path: "/app/customers/add",
      //   form: {
      //     name: "customer",
      //     allowClear: true,
      //     showSearch: true,
      //     filterOption: false,
      //     placeholder: "Customer",
      //     api: "get-all-customers",
      //     options: null,
      //     value: null,
      //     required: false,
      //   },
      // },
      {
        name: "Number",
        type: "input",
        form: {
          type: "text",
          name: "number",
          placeholder: "Purchases Return number",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Date",
        type: "date",
        form: {
          type: "text",
          name: "date",
          placeholder: "Purchases Return date",
          class: "",
          value: "",
          required: true,
        },
      },
      // {
      //   name: "Due Date",
      //   type: "date",
      //   form: {
      //     type: "text",
      //     name: "due_date",
      //     placeholder: "Purchases Return due date",
      //     class: "",
      //     value: "",
      //     required: true,
      //   },
      // },
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
