const headers = {
  name: "Dashboard",
  components: [
    {
      type: "date",
      name: "date",
      value: new Date(),
    },
  ],
};

export default headers;
