import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddButton from "../../../Componets/Buttons/add";
import DotIcon from "../../../Assets/Svg/dot";
import { FormOutlined } from "@ant-design/icons";
import AreaChart from "../../../Componets/Areachart";
import Barchart from "../../../Componets/Barchart";
import SalesIcon from "../../../Assets/Svg/sales";
import PurchaseIcon from "../../../Assets/Svg/purchase";
import ProductIcon from "../../../Assets/Svg/products";
import InventoryIcon from "../../../Assets/Svg/inventory";
import Piechart from "../../../Componets/Piechart";
import billing from "../../../Data/billing.json";
import Header from "../../../Componets/Header";
import headers from "../../../Data/Tables/Dashboard/Dashboard/headers";
import { get_dashboard } from "../../../Controllers/Dashboard/Dashboard";
import moment from "moment";
import toast from "react-hot-toast";
import Loadercomponent from "../../../Componets/Loader";

const Dashboard = () => {
  let navigate = useNavigate();

  // headers.components[0].value = new Date();

  const formdata = (data) => {
    fetchData(data);
  };

  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();

  const handleData = (dashboard) => {};

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      let data = {
        date: tabledata?.date,
      };

      const dashboard = await get_dashboard(data);

      if (dashboard?.status) {
        let data = [...cards];

        data[0].value = dashboard?.data?.inovice_total
          ? parseFloat(dashboard?.data?.inovice_total)?.toFixed(3)
          : "0.000";
        data[1].value = dashboard?.data?.purchase_total
          ? parseFloat(dashboard?.data?.purchase_total)?.toFixed(3)
          : "0.000";
        data[2].value = dashboard?.data?.total_products
          ? parseInt(dashboard?.data?.total_products)
          : "0";
        data[3].value = dashboard?.data?.out_of_stock_count
          ? parseInt(dashboard?.data?.out_of_stock_count)
          : "0";
        data[4].value = dashboard?.data?.inventory_expired_count
          ? parseInt(dashboard?.data?.inventory_expired_count)
          : "0";

        setCards(data);
        setBody(dashboard?.data);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
      setLoader(false);
    }
  };

  const [cards, setCards] = useState([
    {
      icon: <SalesIcon width={40} height={40} />,
      label: "Total Sales",
      value: "0.000",
      color: "bg-violet-100",
      textcolor: "text-violet-600",
    },
    {
      icon: <PurchaseIcon width={40} height={40} />,
      label: "Total Purchase",
      value: "0.000",
      color: "bg-green-100",
      textcolor: "text-green-600",
    },
    {
      icon: <ProductIcon width={40} height={40} />,
      label: "Total Product",
      value: "0",
      color: "bg-indigo-100",
      textcolor: "text-indigo-600",
    },
    {
      icon: <InventoryIcon width={40} height={40} />,
      label: "Out of stock",
      value: "0",
      color: "bg-red-100",
      textcolor: "text-red-600",
    },
    {
      icon: <ProductIcon width={40} height={40} />,
      label: "Expired",
      value: "0",
      color: "bg-orange-100",
      textcolor: "text-orange-600",
    },
  ]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <div className="flex justify-between">
          {cards?.map((value, index) => {
            return (
              <div className={`${value?.color} w-[19%] rounded-md p-6`}>
                <div className="flex justify-between">
                  <div>
                    <div
                      className={`${value?.textcolor} text-4xl text-semi-bold`}
                    >
                      {value?.value}
                    </div>
                    <div className="text-gray-500 text-lg pt-2">
                      {value?.label}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className={`${value?.textcolor} text-2xl`}>
                      {value?.icon}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex pt-8">
          <div className="w-[50%] bg-white rounded-md p-4">
            <div className="px-4">
              <div className="text-gray-500">Statastics</div>
              <div className="pt-1 text-lg text-medium">Total Sales</div>
            </div>
            <Barchart />
          </div>
          <div className="ml-4 w-[50%] bg-white rounded-md p-4">
            <div className="px-4">
              <div className="text-gray-500">Statastics</div>
              <div className="pt-1 text-lg text-medium">Inventory</div>
            </div>
            <Piechart />
          </div>
        </div>

        <div className="pt-8 pb-4">
          <div className="">
            <div className="pb-4 text-xl text-medium">Recent Payment</div>
            <table className="w-full rounded-lg overflow-hidden">
              <thead>
                <tr className="text-white bg-black border-b">
                  <td className="p-3 first:rounded-tl-lg last:rounded-tr-lg">
                    Number
                  </td>
                  <td className="p-3">Date</td>
                  <td className="p-3">Customer</td>
                  <td className="p-3">Created</td>
                  <td className="p-3 text-right first:rounded-tl-lg last:rounded-tr-lg">
                    Amount
                  </td>
                </tr>
              </thead>
              <tbody>
                {body?.recent_invoices?.map(
                  (value, index) =>
                    index <= 5 && (
                      <tr
                        className={`hover:bg-gray-200 bg-white border-b ${
                          index === billing?.length - 1
                            ? "last:rounded-bl-lg last:rounded-br-lg border-none"
                            : ""
                        }`}
                        key={index}
                      >
                        <td className="p-3">{`#INV-${value?.number}`}</td>
                        <td className="p-3">
                          {moment?.(value?.date)?.format?.("DD-MM-YY")}
                        </td>
                        <td className="p-3">
                          {value?.customer?.name
                            ? value?.customer?.name
                            : "Walk-in-customer"}
                        </td>
                        <td className="p-3">{value?.created_by?.name}</td>
                        <td className="p-3 text-right">
                          {parseFloat(value?.total)?.toFixed(3)}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Loadercomponent>
    </Header>
  );
};

export default Dashboard;
