import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_user = async (user) => {
  try {
    const data = {
      name: user?.name,
      phone: user?.phone,
      email: user?.email,
      password: user?.password,
      role: user?.role,
      status: user?.status,
      branch: user?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-user`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_user = async (user) => {
  try {
    const data = {
      id: user?.id,
      name: user?.name,
      phone: user?.phone,
      email: user?.email,
      password: user?.password,
      role: user?.role,
      status: user?.status,
      branch: user?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-user`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_user = async (user) => {
  try {
    const data = {
      id: user?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/delete-user`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_user = async (user) => {
  try {
    const data = {
      id: user?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-user`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_users = async (user) => {
  try {
    const data = {
      search: user?.search,
      status: user?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-all-users`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_user_log = async (user) => {
  try {
    const data = {
      search: user?.search,
      sort: user?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-user-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_user_logs = async (user) => {
  try {
    const data = {
      search: user?.search,
      sort: user?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-user-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_user,
  update_user,
  delete_user,
  get_user,
  get_all_users,
  get_user_log,
  get_all_user_logs,
};
