import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import Table from "../../../../Componets/Table";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Expenses/Categories/tables";
import headers from "../../../../Data/Tables/Expenses/Categories/headers";
import Loadercomponent from "../../../../Componets/Loader";
import { get_all_expense_categories } from "../../../../Controllers/Expenses/Categories";

const CategoriesTable = () => {
  const [loader, setLoader] = useState(false);

  const [header, setHeader] = useState(headers);

  const [body, setBody] = useState();

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const params = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        status: tabledata?.status,
      };

      const categories = await get_all_expense_categories(params);

      if (categories?.status) {
        let data = [];

        categories?.data?.map((value, index) => {
          data?.push({
            Name: value?.name,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/expenses/categories/edit/${value?._id}`}
                id={value?._id}
                api="delete-employee-category"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(categories?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header headers={header} setHeaders={setHeader} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default CategoriesTable;
