import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import Table from "../../../../Componets/Table";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Expenses/Expenses/tables";
import headers from "../../../../Data/Tables/Expenses/Expenses/headers";
import Loadercomponent from "../../../../Componets/Loader";
import { get_all_expenses } from "../../../../Controllers/Expenses/Expenses";
import moment from "moment";

const ExpensesTable = () => {
  const [loader, setLoader] = useState(false);

  const [header, setHeader] = useState(headers);

  const [body, setBody] = useState();

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const params = {
        search: tabledata?.search,
        category: tabledata?.category,
        status: tabledata?.status,
        sort: tabledata?.sort,
        date: tabledata?.date,
      };

      const expenses = await get_all_expenses(params);

      if (expenses?.status) {
        let data = [];

        expenses?.data?.map((value, index) => {
          data?.push({
            Number: value?.number ? `#EX-${value?.number}` : "",
            Date: value?.date
              ? moment?.(value?.date)?.format("MMMM Do YYYY")
              : "",
            Category: value?.category?.name,
            Amount: value?.amount,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/expenses/edit/${value?._id}`}
                id={value?._id}
                api="delete-expense"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(expenses?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header headers={header} setHeaders={setHeader} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default ExpensesTable;
