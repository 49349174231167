import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const get_dashboard = async (dashboard) => {
  try {
    const data = {
      date: dashboard?.date,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-dashboard`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export { get_dashboard };
