import React, { useEffect, useRef, useState } from "react";
import AddButton from "../Buttons/add";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Button, DatePicker, Input, Select } from "antd";
import {
  DownloadOutlined,
  FilterOutlined,
  SearchOutlined,
  SwapOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Container from "../Container";
import { select_search } from "../../Controllers/Global";
import toast from "react-hot-toast";
import dayjs from "dayjs";

const Header = (props) => {
  const { headers, setHeaders, formdata } = props;

  const { RangePicker } = DatePicker;

  let navigate = useNavigate();

  const headerRef = useRef();

  // const [headers, setHeaders] = useState(props?.header);

  const handleFormData = (e) => {
    let data = {};
    headers?.components?.map((value, index) => {
      data[value.name] = value.value;
    });

    setHeaders(headers);
    formdata(data);
  };

  const handleSearch = (e, i) => {
    headers.components[i].value = e?.target?.value;

    setHeaders(headers);
    handleFormData();
  };

  const handleSelect = (e, i) => {
    headers.components[i].value = e;
    setHeaders(headers);
    handleFormData();
  };

  const handleDate = (e, i) => {
    headers.components[i].value = e?.$d;
    setHeaders(headers);
    handleFormData();
  };

  const handleRange = (e, i) => {
    headers.components[i].value.start = e?.[0]?.$d;
    headers.components[i].value.end = e?.[1]?.$d;
    setHeaders(headers);
    handleFormData();
  };

  const handleTabs = (e) => {
    const data = [];
    const lastIndex = e?.length - 1;

    e?.map((value, index) => {
      data.push({
        title: value?.name,
        onClick: () => navigate(value?.path),
        className:
          value?.path && index !== lastIndex
            ? "text-black-200 cursor-pointer hover:text-[#2d53da]"
            : value?.path && index === lastIndex
            ? "breadcrumb-tab-color cursor-pointer hover:text-[#2d53da]"
            : "text-black ",
      });
    });

    return data;
  };

  const handleMenu = (e) => {
    const data = [];

    e?.map((value, index) => {
      data?.push({
        title: value?.name,
        onClick: () => navigate(value?.path),
        className: value?.path ? "cursor-pointer hover:text-[#2d53da]" : "",
      });
    });

    return data;
  };

  const handleSelectSearch = async (e, index) => {
    let data = { ...headers };

    if (data.components[index].api) {
      const select = {
        api: data.components[index].api,
        search: e,
        status: 1,
      };

      const search = await select_search(select);

      if (search?.status) {
        let options = [];
        if (
          data.components[index].name == "product" ||
          data.components[index].name == "catalog" ||
          data.components[index].name == "unit"
        ) {
          search.data.data.forEach((value) => {
            options.push({ value: value._id, label: value.name });
          });
        } else {
          search.data.forEach((value) => {
            options.push({ value: value._id, label: value.name });
          });
        }

        data.components[index].options = options;

        setHeaders(data);
      } else {
        toast.error(search.message);
      }

      // if (search?.status) {
      //   let options = [];

      //   search.data.data.forEach((value) => {
      //     options.push({ value: value._id, label: value.name });
      //   });

      //   data.components[index].options = options;

      //   setHeaders(data);
      // } else {
      //   toast.error(search.message);
      // }
    }
  };

  useEffect(() => {
    headerRef?.current?.focus();
  }, []);

  return (
    <div>
      <div className="fixed top-16 left-64 right-0 z-20">
        <div className="p-4 flex justify-between items-center border-b bg-white h-[60px]">
          <div className="outline-none" ref={headerRef} tabIndex={-1}>
            {headers?.name}
          </div>
          <div>
            <div className="flex">
              {headers?.components?.map((value, index) => {
                return (
                  <div
                    className={
                      headers?.components?.length == index + 1 ? "" : "pr-2"
                    }
                  >
                    {value?.type == "search" ? (
                      <Input
                        allowClear
                        className="search"
                        placeholder=" Search"
                        prefix={
                          <span className="text-gray-500 text-medium">
                            <SearchOutlined />
                          </span>
                        }
                        onChange={(e) => handleSearch(e, index)}
                      />
                    ) : value?.type == "select" || value?.type == "filter" ? (
                      <Select
                        allowClear={value?.allowClear}
                        showSearch={value?.showSearch}
                        filterOption={value?.filterOption}
                        className="w-32"
                        placeholder={
                          <span>
                            {value?.type === "select" ? (
                              <SwapOutlined
                                style={{ transform: "rotate(90deg)" }}
                              />
                            ) : value?.type === "filter" ? (
                              <FilterOutlined />
                            ) : (
                              ""
                            )}
                            <span className="pl-2">{value?.placeholder}</span>
                          </span>
                        }
                        options={value?.options}
                        onClick={() => handleSelectSearch("", index)}
                        onSearch={(e) => handleSelectSearch(e, index)}
                        onChange={(e) => handleSelect(e, index)}
                        autoComplete="off"
                      />
                    ) : value?.type == "upload" ? (
                      <Button className="text-[#BFBFBF]">
                        <div className="flex">
                          <div className="">Export</div>
                          <div className="pl-4 pt-0.5">
                            <UploadOutlined />
                          </div>
                        </div>
                      </Button>
                    ) : value?.type == "date" ? (
                      <DatePicker
                        value={value?.value && dayjs(value?.value)}
                        onChange={(e) => handleDate(e, index)}
                      />
                    ) : value?.type == "range" ? (
                      <RangePicker onChange={(e) => handleRange(e, index)} />
                    ) : value?.type == "download" ? (
                      <Button className="text-[#BFBFBF]">
                        <div className="flex">
                          <div className="">Import</div>
                          <div className="pl-4 pt-0.5">
                            <DownloadOutlined />
                          </div>
                        </div>
                      </Button>
                    ) : value?.type == "add" ? (
                      <AddButton onClick={() => navigate(value?.path)} />
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="p-4 bg-white border-b h-[40px] flex justify-between items-center text-xs text-medium text-gray-400 uppercase">
          <div>
            <Breadcrumb
              className="text-regular"
              separator=">"
              items={handleMenu(headers?.navigate?.path)}
            />
            <Breadcrumb
              className="text-regular"
              separator=">"
              items={handleTabs(headers?.navigate?.tabs)}
              lastItemColor="yellow"
            />
          </div>
          <div className="flex">
            {headers?.navigate?.total?.map((value, index) => {
              return (
                <div
                  className={
                    index == headers?.navigate?.total?.length - 1
                      ? "cursor-pointer my-auto"
                      : "pr-3 cursor-pointer my-auto"
                  }
                >
                  {value?.label} ({value?.value})
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Container>{props?.children}</Container>
    </div>
  );
};

export default Header;
