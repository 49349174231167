import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_invoice = async (invoice) => {
  try {
    const data = {
      customer: invoice?.customer,
      project: invoice?.project,
      number: invoice?.number,
      date: invoice?.date,
      due_date: invoice?.due_date,
      status: invoice?.status,
      details: invoice?.details,
      subtotal: invoice?.subtotal,
      taxamount: invoice?.taxamount,
      discount: invoice?.discount,
      delivery: invoice?.delivery,
      delivery_status: invoice?.delivery_status,
      delivery_date: invoice?.delivery_date,
      payment_status: invoice?.payment_status,
      payment_types: JSON?.stringify(invoice?.payment_types),
      payments: JSON?.stringify(invoice?.payments),
      total: invoice?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-invoice`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_invoice = async (invoice) => {
  try {
    const data = {
      id: invoice?.id,
      customer: invoice?.customer,
      project: invoice?.project,
      number: invoice?.number,
      date: invoice?.date,
      due_date: invoice?.due_date,
      status: invoice?.status,
      details: invoice?.details,
      subtotal: invoice?.subtotal,
      taxamount: invoice?.taxamount,
      discount: invoice?.discount,
      delivery: invoice?.delivery,
      delivery_status: invoice?.delivery_status,
      delivery_date: invoice?.delivery_date,
      payment_status: invoice?.payment_status,
      payment_types: JSON?.stringify(invoice?.payment_types),
      payments: JSON?.stringify(invoice?.payments),
      total: invoice?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-invoice`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_invoice = async (invoice) => {
  try {
    const data = {
      id: invoice?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/delete-invoice`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_invoice = async (invoice) => {
  try {
    const data = {
      id: invoice?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-invoice`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_invoices = async (invoice) => {
  try {
    const data = {
      search: invoice?.search,
      sort: invoice?.sort,
      supplier: invoice?.supplier,
      contractor: invoice?.contractor,
      date: invoice?.date,
      due_date: invoice?.due_date,
      page: invoice?.page,
      status: invoice?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-invoices`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_invoices_details = async (invoice) => {
  try {
    const data = {
      search: invoice?.search,
      status: invoice?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-invoices-details`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_invoice_log = async (invoice) => {
  try {
    const data = {
      search: invoice?.search,
      sort: invoice?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-invoice-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_invoice_logs = async (invoice) => {
  try {
    const data = {
      search: invoice?.search,
      sort: invoice?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-invoice-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_invoice,
  update_invoice,
  delete_invoice,
  get_invoice,
  get_all_invoices,
  get_all_invoices_details,
  get_invoice_log,
  get_all_invoice_logs,
};
