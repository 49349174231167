import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_sales_return = async (sales_return) => {
  try {
    const data = {
      invoice: sales_return?.invoice,
      customer: sales_return?.customer,
      project: sales_return?.project,
      number: sales_return?.number,
      date: sales_return?.date,
      due_date: sales_return?.due_date,
      status: sales_return?.status,
      details: sales_return?.details,
      subtotal: sales_return?.subtotal,
      taxamount: sales_return?.taxamount,
      discount: sales_return?.discount,
      delivery: sales_return?.delivery,
      delivery_status: sales_return?.delivery_status,
      delivery_date: sales_return?.delivery_date,
      payment_status: sales_return?.payment_status,
      payment_types: JSON?.stringify(sales_return?.payment_types),
      payments: JSON?.stringify(sales_return?.payments),
      total: sales_return?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-sales-return`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_sales_return = async (sales_return) => {
  try {
    const data = {
      id: sales_return?.id,
      invoice: sales_return?.invoice,
      customer: sales_return?.customer,
      project: sales_return?.project,
      number: sales_return?.number,
      date: sales_return?.date,
      due_date: sales_return?.due_date,
      status: sales_return?.status,
      details: sales_return?.details,
      subtotal: sales_return?.subtotal,
      taxamount: sales_return?.taxamount,
      discount: sales_return?.discount,
      delivery: sales_return?.delivery,
      delivery_status: sales_return?.delivery_status,
      delivery_date: sales_return?.delivery_date,
      payment_status: sales_return?.payment_status,
      payment_types: JSON?.stringify(sales_return?.payment_types),
      payments: JSON?.stringify(sales_return?.payments),
      total: sales_return?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-sales-return`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_sales_return = async (sales_return) => {
  try {
    const data = {
      id: sales_return?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/delete-sales-return`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_sales_return = async (sales_return) => {
  try {
    const data = {
      id: sales_return?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-sales-return`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_sales_returns = async (sales_return) => {
  try {
    const data = {
      search: sales_return?.search,
      sort: sales_return?.sort,
      supplier: sales_return?.supplier,
      contractor: sales_return?.contractor,
      date: sales_return?.date,
      due_date: sales_return?.due_date,
      page: sales_return?.page,
      status: sales_return?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-sales-returns`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_sales_returns_details = async (sales_return) => {
  try {
    const data = {
      search: sales_return?.search,
      status: sales_return?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-sales-returns-details`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_sales_return_log = async (sales_return) => {
  try {
    const data = {
      search: sales_return?.search,
      sort: sales_return?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-sales-return-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_sales_return_logs = async (sales_return) => {
  try {
    const data = {
      search: sales_return?.search,
      sort: sales_return?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-sales-return-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_sales_return,
  update_sales_return,
  delete_sales_return,
  get_sales_return,
  get_all_sales_returns,
  get_all_sales_returns_details,
  get_sales_return_log,
  get_all_sales_return_logs,
};
