import { AutoComplete, DatePicker, Form, Input, Select } from "antd";
import React, { createRef, useEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import AddButton from "../../../../Componets/Buttons/add";
import { select_id, select_search } from "../../../../Controllers/Global";
import dayjs from "dayjs";
import moment from "moment";
import taxes from "../../../taxes.json";
import payment_types from "../../../payment_types.json";
import Error from "../../../../Componets/Error";
import Modalcomponent from "../../../../Componets/Modal";
import { get_inventory } from "../../../../Controllers/Inventory/Batch";

const Invoicetable = React.forwardRef((props, focus) => {
  const { details, setDetails, totals, setTotals } = props;

  focus.current[`delivery_date`] = createRef();
  focus.current[`payment_types`] = createRef();

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [selcectedModalData, setSelcectedModalData] = useState();

  const handleSearch = async (e, index) => {
    let data = [...details];

    const select = {
      api: "get-all-inventories",
      search: e,
      status: 1,
    };

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.data?.map((value, index) => {
        options?.push({
          value: value?._id,
          label:
            value?.product?.name +
            ` (Batch-${value?.number}) ` +
            ` ${
              value?.expiry_date
                ? `(${moment?.(value?.expiry_date)?.format?.("DD-MM-YY")})`
                : ""
            } `,
        });
      });

      data[index].options = options;

      setDetails(data);
    } else {
      toast?.error(search?.message);
    }
  };

  const handleModal = (modaldata) => {
    let data = [...details];

    let inventory = selcectedModalData;
    let index = selcectedModalData?.index;

    let inventory_description = null;
    let inventory_name = "";
    let inventory_batch = "";
    let inventory_expiry_date = "";
    let inventory_price_per_unit = "";
    let inventory_unit = "";
    let inventory_unit_name = "";
    let inventory_sale_price = 0;
    let inventory_stock = 0;
    let inventory_tax = 0;
    let inventory_conversion = 0;
    let inventory_unit_details = [];

    let description_ids = [];

    data?.map((v, i) => {
      description_ids?.push(v?.unit);
    });

    if (!description_ids?.includes(modaldata)) {
      if (inventory?.data?._id == modaldata) {
        inventory_description = inventory?.data?._id;
        inventory_name = inventory?.data?.product?.name;
        inventory_batch = inventory?.data?.number;
        inventory_expiry_date = inventory?.data?.expiry_date;
        inventory_price_per_unit = inventory?.data?.price_per_unit;
        inventory_unit = modaldata;
        inventory_unit_name = inventory?.data?.product?.unit?.name;
        inventory_sale_price = inventory?.data?.sale_price;
        inventory_stock = inventory?.data?.stock;
        inventory_tax = inventory?.data?.tax;

        if (inventory?.data?.inventory_unit_details?.length > 0) {
          inventory?.data?.inventory_unit_details?.map((value, index) => {
            inventory_unit_details?.push({
              ...value,
              name: value?.name,
              purchase_price: value?.purchase_price,
              price_per_unit: value?.price_per_unit,
              sale_price: value?.sale_price,
              stock: value?.stock,
              unit_quantity: 0,
              unit_delivered: 0,
            });
          });
        }
      } else {
        let selected_unit = "";
        let unit_ids = [];

        if (inventory?.data?.inventory_unit_details?.length > 0) {
          inventory?.data?.inventory_unit_details?.map((value, index) => {
            unit_ids?.push(value?._id);
          });
        }

        if (unit_ids?.includes(modaldata)) {
          selected_unit =
            inventory?.data?.inventory_unit_details?.[
              unit_ids?.indexOf(modaldata)
            ];
        }

        inventory_description = inventory?.data?._id;
        inventory_name = inventory?.data?.product?.name;
        inventory_batch = inventory?.data?.number;
        inventory_expiry_date = inventory?.data?.expiry_date;
        inventory_unit = modaldata;
        inventory_unit_name = selected_unit?.name;
        inventory_sale_price = selected_unit?.sale_price;
        inventory_stock = selected_unit?.stock;
        inventory_tax = inventory?.data?.tax;
        inventory_conversion = selected_unit?.conversion;
      }

      data[index].description = inventory_description;
      data[index].name = inventory_name;
      data[index].batch = inventory_batch;
      data[index].expiry_date = inventory_expiry_date;
      data[index].unit = inventory_unit;
      data[index].unit_name = inventory_unit_name;
      data[index].quantity = "";
      data[index].sale_price = inventory_sale_price;
      data[index].stock = inventory_stock;
      data[index].tax = inventory_tax;
      data[index].conversion = inventory_conversion;
      data[index].unit_details_options = inventory_unit_details;

      focus.current[`quantity${index}`].current.focus();

      setDetails(data);
      setModalData();
      setSelcectedModalData();
    } else {
      let selected_index = description_ids?.indexOf(inventory?.data?._id);

      if (
        parseFloat(data[selected_index].quantity || 0) <
        parseFloat(data[selected_index].stock || 0)
      ) {
        let quantity = data[selected_index].quantity
          ? data[selected_index].quantity
          : 0;

        if (
          data?.[selected_index]?.conversion > 0 &&
          data?.[selected_index]?.quantity + parseFloat(1) >=
            data?.[selected_index]?.conversion
        ) {
          toast?.error(
            `Quantity exceeding ${data?.[selected_index]?.conversion}`
          );
        } else {
          data[selected_index].quantity = parseFloat(quantity) + parseFloat(1);
          data[selected_index].delivered = parseFloat(quantity) + parseFloat(1);
        }

        setDetails(data);
        setModalData();
        setSelcectedModalData();
      } else {
        toast?.error(`Stock only ${data[selected_index].stock}`);
      }
    }
  };

  const handleDescription = async (e, index) => {
    let data = [...details];

    if (e) {
      let params = {
        id: e,
      };
      const inventory = await get_inventory(params);

      if (inventory?.data?.inventory_unit_details?.length > 0) {
        setOpen(true);

        let modaldata = [
          { ...inventory?.data, name: inventory?.data?.product?.unit?.name },
        ];

        inventory?.data?.inventory_unit_details?.map((v, i) => {
          modaldata?.push(v);
        });

        setModalData(modaldata);

        setSelcectedModalData({ ...inventory, index: index });
      } else {
        let description_ids = [];

        data?.map((v, i) => {
          description_ids?.push(v?.description);
        });

        if (!description_ids?.includes(e)) {
          let inventory_description = inventory?.data?._id;
          let inventory_name = inventory?.data?.product?.name;
          let inventory_batch = inventory?.data?.number;
          let inventory_expiry_date = inventory?.data?.expiry_date;
          let inventory_unit = inventory?.data?._id;
          let inventory_unit_name = inventory?.data?.product?.unit?.name;
          let inventory_sale_price = inventory?.data?.sale_price;
          let inventory_stock = inventory?.data?.stock;
          let inventory_tax = inventory?.data?.tax;

          let inventory_unit_details = [];
          if (inventory?.data?.inventory_unit_details?.length > 0) {
            inventory?.data?.inventory_unit_details?.map((value, index) => {
              inventory_unit_details?.push({
                ...value,
                name: value?.name?.name,
                price_per_unit: 0,
                sale_price: "",
                unit_quantity: 0,
                unit_delivered: 0,
              });
            });
          }

          data[index].description = inventory_description;
          data[index].name = inventory_name;
          data[index].batch = inventory_batch;
          data[index].expiry_date = inventory_expiry_date;
          data[index].unit = inventory_unit;
          data[index].unit_name = inventory_unit_name;
          data[index].sale_price = inventory_sale_price;
          data[index].stock = inventory_stock;
          data[index].tax = inventory_tax;
          data[index].quantity = "";
          data[index].unit_details_options = inventory_unit_details;
          setDetails(data);

          focus.current[`quantity${index}`].current.focus();
        } else {
          let selected_index = description_ids?.indexOf(e);

          if (
            parseFloat(data[selected_index].quantity || 0) <
            parseFloat(data[selected_index].stock || 0)
          ) {
            let quantity = data[selected_index].quantity
              ? data[selected_index].quantity
              : 0;
            let delivered = data[selected_index].delivered
              ? data[selected_index].delivered
              : 0;

            data[selected_index].quantity =
              parseFloat(quantity) + parseFloat(1);
            data[selected_index].delivered =
              parseFloat(delivered) + parseFloat(1);

            setDetails(data);
          } else {
            toast?.error(`Stock only ${data[selected_index].stock}`);
          }
        }
      }
    } else {
      data[index].id = "";
      data[index].description = null;
      data[index].name = "";
      data[index].batch = "";
      data[index].expiry_date = "";
      data[index].unit = "";
      data[index].unit_name = "";
      data[index].unit_details_options = null;
      data[index].quantity = "";
      data[index].conversion = 0;
      data[index].purchase = "";
      data[index].sale_price = "";
      data[index].purchase_options = null;
      data[index].delivered = "";
      data[index].tax = 0;
      data[index].free = "";
      data[index].barcode = "";
      data[index].price_per_unit = "";
      data[index].sale_price = "";
      data[index].expiry_date = "";
      data[index].tax_amount = "0.000";
      data[index].total = 0;
      setDetails(data);
    }
  };

  const handlePurchasePriceSearch = async (e, index) => {
    let data = [...details];

    if (data[index].description) {
      const select = {
        api: "get-all-purchase-details",
        id: data[index].description,
      };

      const search = await select_id(select);
      if (search?.status) {
        let options = [];
        search?.data?.map((value, index) => {
          options?.push({
            value: JSON?.stringify(value),
            label:
              "" +
              value?.price_per_unit?.toFixed(3) +
              " (" +
              value?.purchase?.supplier?.name +
              ", " +
              moment?.(value?.purchase?.date)?.format?.("DD/MM/YYYY") +
              ", #PO" +
              value?.purchase?.number +
              ")",
          });
        });

        data[index].purchase_options = options;
        setDetails(data);
      } else {
        toast?.error(search?.message);
      }
    }
  };

  const handleTotal = (e, index, key) => {
    let name = key ? key : e?.target?.name;
    let value = key && e ? e : !key && e?.target?.value ? e?.target?.value : "";

    if (value >= 0) {
      let data = [...details];

      if (value <= data[index]?.stock) {
        let data_sale_price = data[index]?.sale_price
          ? data[index]?.sale_price
          : 0;
        let data_quantity = data[index]?.quantity ? data[index]?.quantity : 0;
        let data_deliverd = data[index]?.delivered ? data[index]?.delivered : 0;
        let data_free = data[index]?.free ? data[index]?.free : 0;
        let data_tax = data[index]?.tax ? data[index]?.tax : 0;
        let data_conversion = data[index].conversion
          ? data[index].conversion
          : 0;

        let sale_price = name === "sale_price" ? value : data_sale_price;
        let quantity = name === "quantity" ? value : data_quantity;
        let delivered = name === "quantity" ? value : data_deliverd;
        let free = name === "free" ? value : data_free;
        let tax = name === "tax" ? value : data_tax;

        let tax_amount = tax
          ? parseFloat(sale_price) *
            parseFloat(quantity ? quantity : 0) *
            (parseFloat(tax) / 100)
          : 0;

        let total =
          parseFloat(sale_price) * parseFloat(quantity ? quantity : 0) +
          parseFloat(tax_amount);

        let price_per_unit =
          parseFloat(total ? total : 0) /
          (parseFloat(quantity ? quantity : 0) + parseFloat(free ? free : 0));

        let unit_details_options = [];
        if (data?.[index]?.unit_details_options?.length > 0) {
          data?.[index]?.unit_details_options?.map((v, i) => {
            let total_quantity =
              parseFloat(quantity || 0) + parseFloat(free || 0);

            unit_details_options?.push({
              ...v,
              //price_per_unit
              price_per_unit: price_per_unit
                ? parseFloat(price_per_unit || 0) /
                  parseFloat(v?.conversion || 0)
                : 0,
              //unit_quantity
              unit_quantity: quantity
                ? parseFloat(v?.conversion || 0) *
                  parseFloat(total_quantity || 0)
                : 0,
              //unit_deliverd
              unit_delivered: delivered
                ? parseFloat(v?.conversion || 0) *
                  parseFloat(total_quantity || 0)
                : 0,
            });
          });
        }

        if (
          data_conversion > 0 &&
          parseFloat(quantity) + parseFloat(free) >= data_conversion
        ) {
          toast?.error(`Quantiy exceeding ${data[index].conversion}`);
        } else {
          data[index][name] = value;
          data[index].unit_details_options = unit_details_options;
          data[index].delivered = delivered ? delivered : "";
          data[index].tax = tax ? tax : 0;
          data[index].total = total ? total : 0;
          data[index].tax_amount = tax_amount ? tax_amount : "0.000";
          data[index].price_per_unit = price_per_unit ? price_per_unit : 0;

          let subtotal =
            data?.reduce(
              (acc, currentValue) =>
                parseFloat(acc) + parseFloat(currentValue?.total),
              0
            ) -
            data?.reduce(
              (acc, currentValue) =>
                parseFloat(acc) + parseFloat(currentValue?.tax_amount),
              0
            );

          let taxamount = data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.tax_amount),
            0
          );

          let grandtotal = data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.total),
            0
          );

          totals.subtotal = subtotal;
          totals.taxamount = taxamount;
          totals.delivery_status = 0;
          totals.delivery_date = "";
          totals.payment_status = 0;
          totals.payment_types = null;
          totals.payments = {
            Cash: "",
            Cheque: "",
            "Debit card": "",
            "Credit card": "",
            "Bank transfer": "",
            "Online payment": "",
          };
          totals.total = grandtotal;

          setDetails(data);
          setTotals({ ...totals });
        }
      } else {
        toast?.error(`Stock only ${data[index]?.stock}`);
      }
    }
  };

  const handleChange = (e, index, key) => {
    let data = [...details];

    data[index][e?.target?.name] = e?.target?.value;
    setDetails(data);
  };

  const handleSalePrice = (e, index) => {
    if (e?.target?.value >= 0) {
      let data = [...details];

      data[index].sale_price = e?.target?.value;

      if (data[index]?.unit_details_options?.length > 0) {
        data[index]?.unit_details_options?.map((v, i) => {
          data[index].unit_details_options[i].sale_price = (
            parseFloat(e?.target?.value) / parseFloat(v?.conversion)
          )?.toFixed(3);
        });
      }

      setDetails(data);
    }
  };

  const handleUnitSalePrice = (e, index, i) => {
    let data = [...details];

    let name = e?.target?.name;
    let value = e?.target?.value;

    if (name) {
      if (value >= 0) {
        data[index].unit_details_options[i].sale_price = value;
        setDetails(data);
      }
    }
  };

  const handleDate = (e, index, name) => {
    let data = [...details];
    data[index][name] = e?.$d;

    setDetails(data);
  };

  const handleDelivered = (e, index) => {
    let data = [...details];

    let data_delivered = e?.target?.value ? parseFloat(e?.target?.value) : 0;

    let data_quantity = data[index]?.quantity
      ? parseFloat(data[index]?.quantity)
      : 0;
    let data_free = data[index]?.free ? parseFloat(data[index]?.free) : 0;
    let total_quantity =
      parseFloat(data_quantity || 0) + parseFloat(data_free || 0);

    if (data_delivered >= 0) {
      if (data_delivered <= total_quantity) {
        let unit_details_options = [];
        if (data?.[index]?.unit_details_options?.length > 0) {
          data?.[index]?.unit_details_options?.map((v, i) => {
            unit_details_options?.push({
              ...v,
              //delivered_stcok
              unit_delivered: data_delivered
                ? parseFloat(data_delivered) * parseFloat(v?.conversion)
                : 0,
            });
          });
        }

        data[index][e?.target?.name] = e?.target?.value;
        data[index].unit_details_options = unit_details_options;

        setDetails(data);
      } else {
        toast?.error(`Quantity only ${total_quantity}`);
      }
    }
  };

  const handleRemove = (index) => {
    let data = [...details];

    data?.splice(index, 1);

    setDetails(data);
  };

  const handleAdd = () => {
    setDetails((prevDetails) => [
      ...prevDetails,
      {
        id: "",
        description: null,
        name: "",
        unit: "",
        unit_name: "",
        unit_details_options: null,
        quantity: "",
        purchase: "",
        sale_price: "",
        purchase_options: null,
        delivered: "",
        tax: 0,
        free: "",
        barcode: "",
        price_per_unit: "",
        sale_price: "",
        expiry_date: "",
        tax_amount: "0.000",
        total: 0,
      },
    ]);

    setTimeout(() => {
      focus.current[`description${details?.length}`].current.focus();
    }, 100);
  };

  const handleDelivery = (e) => {
    if (e?.target?.value >= 0) {
      let total_amount = parseFloat(
        details?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        )
      );

      let total =
        parseFloat(total_amount ? total_amount : 0) +
        parseFloat(e?.target?.value ? e?.target?.value : 0) -
        parseFloat(totals?.discount ? totals?.discount : 0);

      totals.delivery = e?.target?.value;
      totals.total = total;

      setTotals({ ...totals });
    }
  };

  const handleDiscount = (e) => {
    if (e?.target?.value >= 0) {
      let total_amount = parseFloat(
        details?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        )
      );

      let total =
        parseFloat(total_amount ? total_amount : 0) +
        parseFloat(totals?.delivery ? totals?.delivery : 0) -
        parseFloat(e?.target?.value ? e?.target?.value : 0);

      if (e?.target?.value <= total_amount) {
        totals.discount = e?.target?.value;
        totals.total = total;
      } else {
        toast?.error(`Total amount ${total_amount}`);
      }

      setTotals({ ...totals });
    }
  };

  const handleDeliveryStatus = (e) => {
    totals.delivery_status = e;

    if (!e) {
      totals.delivery_date = "";
    } else if (e == 1) {
      totals.delivery_date = "";
    }

    setTotals({ ...totals });
  };

  const handleDeliveryDate = (e) => {
    totals.delivery_date = e?.$d;
    setTotals({ ...totals });
  };

  const handlePaymentStatus = (e) => {
    totals.payment_status = e;

    if (!e) {
      totals.payment_types = null;
    }
    setTotals({ ...totals });
  };

  const handlePaymentTypes = (e) => {
    totals?.payment_types?.map((v, i) => {
      if (!e?.includes(v)) {
        totals.payments[v] = "";
      }
    });

    totals.payment_types = e;

    setTotals({ ...totals });
  };

  const handlePayments = (e) => {
    console.log(e?.target?.name, e?.target?.value);
    let name = e?.target?.name;
    let value = e?.target?.value ? e?.target?.value : "";

    if (e?.target?.value >= 0) {
      let data_Cash = totals?.payments?.Cash?.amount
        ? totals?.payments?.Cash?.amount
        : 0;
      let data_Cheque = totals?.payments?.Cheque?.amount
        ? totals?.payments?.Cheque?.amount
        : 0;
      let data_Debitcard = totals?.payments?.["Debit card"]?.amount
        ? totals?.payments?.["Debit card"]?.amount
        : 0;
      let data_Creditcard = totals?.payments?.["Credit card"]?.amount
        ? totals?.payments?.["Credit card"]?.amount
        : 0;
      let data_Banktransfer = totals?.payments?.["Bank transfer"]?.amount
        ? totals?.payments?.["Bank transfer"]?.amount
        : 0;
      let data_Onlinepayment = totals?.payments?.["Online payment"]?.amount
        ? totals?.payments?.["Online payment"]?.amount
        : 0;

      let Cash = name === "Cash" ? value : data_Cash;
      let Cheque = name === "Cheque" ? value : data_Cheque;
      let Debitcard = name === "Debit card" ? value : data_Debitcard;
      let Creditcard = name === "Credit card" ? value : data_Creditcard;
      let Banktransfer = name === "Bank transfer" ? value : data_Banktransfer;
      let Onlinepayment =
        name === "Online payment" ? value : data_Onlinepayment;

      let total =
        parseFloat(Cash) +
        parseFloat(Cheque) +
        parseFloat(Debitcard) +
        parseFloat(Creditcard) +
        parseFloat(Banktransfer) +
        parseFloat(Onlinepayment);

      let payment_total = total ? parseFloat(total) : 0;
      let grand_total = totals?.total ? parseFloat(totals?.total) : 0;

      if (payment_total <= grand_total) {
        totals.payments[name] = { ...totals.payments[name], amount: value };
        setTotals({ ...totals });
      } else {
        let previous_total =
          parseFloat(data_Cash) +
          parseFloat(data_Cheque) +
          parseFloat(data_Debitcard) +
          parseFloat(data_Creditcard) +
          parseFloat(data_Banktransfer) +
          parseFloat(data_Onlinepayment);

        let previous_remaining_amount =
          parseFloat(grand_total) - parseFloat(previous_total);

        toast?.error(`Total only ${grand_total}`);
      }
    }
  };

  return (
    <div>
      <Modalcomponent
        open={open}
        setOpen={setOpen}
        modalData={modalData}
        setModalData={setModalData}
        selcectedModalData={selcectedModalData}
        setSelcectedModalData={setSelcectedModalData}
        handleModal={handleModal}
      />
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">S.No</td>
            <td className="border p-4 text-black">Description</td>
            <td className="border p-4 text-black">Unit</td>
            <td className="border p-4 text-black">Sale price</td>
            <td className="border p-4 text-black">Quantity</td>
            <td className="border p-4 text-black">Delivered</td>
            <td className="border p-4 text-black">Tax</td>
            <td className="border p-4 text-black">Total</td>
            <td className="border p-4 text-black">Delete</td>
          </tr>
        </thead>
        <tbody>
          {details?.map((value, index) => {
            focus.current[`description${index}`] = createRef();
            focus.current[`sale_price${index}`] = createRef();
            focus.current[`quantity${index}`] = createRef();
            focus.current[`sale_price${index}`] = createRef();
            return (
              <React.Fragment>
                <tr>
                  <td className="border p-4 text-center">{index + 1}</td>
                  <td className="border p-4">
                    {value?.description ? (
                      <div className="w-[300px]">
                        <div className="text-black">{value?.name}</div>
                        <div className="text-xs">{`BATCH-${value?.batch}`}</div>
                        <div className="text-xs">
                          {value?.expiry_date
                            ? `Expiry (${moment?.(value?.expiry_date)?.format(
                                "DD-MM-YY"
                              )})`
                            : ""}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <Select
                          className="w-[300px] text-regular"
                          name="description"
                          ref={focus.current[`description${index}`]}
                          placeholder="Description"
                          allowClear={true}
                          showSearch={true}
                          filterOption={false}
                          value={value?.description}
                          options={value?.options}
                          onSearch={(e) => handleSearch(e, index)}
                          onClick={(e) => handleSearch("", index)}
                          onChange={(e) => handleDescription(e, index)}
                          autoComplete="off"
                        />
                      </div>
                    )}
                    <Error id={`description${index}`} />
                  </td>
                  <td className="border p-4 text-center">
                    <div>{value?.unit_name}</div>
                  </td>
                  <td className="border p-4 text-center">
                    <div>
                      <div>
                        <AutoComplete
                          className="w-[150px] text-regular"
                          name="sale_price"
                          ref={focus.current[`sale_price${index}`]}
                          placeholder={`Sale price`}
                          allowClear={true}
                          showSearch={true}
                          filterOption={false}
                          options={value?.purchase_options}
                          value={value?.sale_price}
                          onClick={(e) => handlePurchasePriceSearch("", index)}
                          // onChange={(e) => handleTotal(e, index, "sale_price")}
                          autoComplete="off"
                        />
                        <Error
                          className="pt-0.5 text-xs text-red-500 text-left"
                          id={`sale_price${index}`}
                        />
                      </div>
                    </div>
                  </td>
                  <td className="border px-4 align-top">
                    <div className="flex flex-col items-start">
                      <div className="text-xs text-green-500">
                        Stock ({value?.stock})
                      </div>
                      <Input
                        type="number"
                        className="w-full text-regular"
                        name="quantity"
                        ref={focus.current[`quantity${index}`]}
                        placeholder="Quantity"
                        value={value?.quantity}
                        onChange={(e) => handleTotal(e, index)}
                        autoComplete="off"
                      />
                      <div>
                        <Error id={`quantity${index}`} />
                      </div>
                    </div>
                  </td>

                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full text-regular"
                      name="delivered"
                      placeholder="Delivered"
                      value={value?.delivered}
                      onChange={(e) => handleDelivered(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    <div>{value?.tax ? value?.tax + "%" : "0%"}</div>
                    <div>
                      {value?.tax_amount
                        ? parseFloat(value?.tax_amount)?.toFixed(3)
                        : "0.000"}
                    </div>
                  </td>
                  <td className="border p-4 text-center">
                    {value?.total
                      ? parseFloat?.(value?.total)?.toFixed(3)
                      : "0.000"}
                  </td>
                  <td className="border p-4 text-center">
                    <button
                      type="button"
                      className="text-lg text-red-500 cursor-pointer hover:text-red-800"
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteOutlined />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="border p-4 text-center"></td>
                  <td className="border p-4"></td>
                  <td className="border p-4"></td>
                  <td className="border p-4"></td>
                  <td className="border p-4">
                    {/* units details unit_quantity */}
                    <div>
                      {value?.unit_details_options?.map((v, i) => {
                        return (
                          <div className="pt-2">
                            <div className="pb-2">
                              {v?.name}({v?.conversion})
                            </div>
                            <div>
                              <div className="border border-gray-300 p-1 rounded-md">
                                <div>{v?.unit_quantity}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                  <td className="border p-4">
                    {/* units details unit_delivered */}
                    <div>
                      {value?.unit_details_options?.map((v, i) => {
                        return (
                          <div className="pt-2">
                            <div className="pb-2">
                              {v?.name}({v?.conversion})
                            </div>
                            <div>
                              <div className="border border-gray-300 p-1 rounded-md">
                                <div>{v?.unit_delivered}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                  <td className="border p-4"></td>
                  <td className="border p-4"></td>
                  <td className="border p-4 text-center" colSpan={1}></td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4"></td>
            <td className="border p-4 text-center" colSpan={2}>
              <AddButton onClick={handleAdd} />
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Subtotal</td>
            <td className="border p-4" colSpan={2}>
              {totals?.subtotal > 0 ? totals?.subtotal?.toFixed(3) : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Tax&nbsp;amount</td>
            <td className="border p-4" colSpan={2}>
              {totals?.taxamount > 0 ? totals?.taxamount?.toFixed(3) : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Discount&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <Input
                type="number"
                className="w-full"
                placeholder="Discount amount"
                name="discount"
                value={totals?.discount}
                autoComplete="off"
                onChange={handleDiscount}
              />
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Delivery&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <Input
                type="number"
                className="w-full"
                placeholder="Delivery charges"
                name="delivery"
                value={totals?.delivery}
                autoComplete="off"
                onChange={handleDelivery}
              />
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Delivery status&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <div>
                <div>
                  <Select
                    className="w-full"
                    name="tax"
                    placeholder="Delivery status"
                    value={totals?.delivery_status}
                    options={[
                      {
                        value: 0,
                        label: "Pending",
                      },
                      {
                        value: 1,
                        label: "Partial",
                      },
                      {
                        value: 2,
                        label: "Delivered",
                      },
                    ]}
                    onChange={handleDeliveryStatus}
                  />
                </div>
                {/* {focus.current[`delivery_date`] = createRef()} */}
                {totals?.delivery_status == 2 && (
                  <div className="pt-4">
                    <DatePicker
                      type="number"
                      name="delivery_date"
                      ref={focus.current[`delivery_date`]}
                      className="w-full"
                      placeholder="Delivered date"
                      value={
                        totals?.delivery_date && dayjs(totals?.delivery_date)
                      }
                      onChange={handleDeliveryDate}
                      autoComplete="off"
                    />
                    <Error id={`delivery_date`} />
                  </div>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Payment status&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <div>
                <Select
                  className="w-full"
                  name="tax"
                  placeholder="Payment status"
                  value={totals?.payment_status}
                  options={[
                    {
                      value: 0,
                      label: "Unpaid",
                    },
                    {
                      value: 1,
                      label: "Partial",
                    },
                    {
                      value: 2,
                      label: "Paid",
                    },
                  ]}
                  onChange={handlePaymentStatus}
                />
              </div>
              {totals?.payment_status == 1 || totals?.payment_status == 2 ? (
                <div className="pt-4">
                  <Select
                    mode="multiple"
                    className="w-full"
                    name="payment_types"
                    ref={focus.current[`payment_types`]}
                    placeholder="Payment types"
                    value={totals?.payment_types}
                    options={payment_types}
                    onChange={handlePaymentTypes}
                  />
                  <Error id={`payment_types`} />
                </div>
              ) : (
                ""
              )}
              {totals?.payment_types?.map((value, index) => {
                focus.current[`${value}`] = createRef();
                return (
                  <div className="pt-4">
                    <Input
                      type="number"
                      className="w-full"
                      placeholder={value}
                      name={value}
                      ref={focus.current[`${value}`]}
                      value={totals?.payments?.[value]?.amount}
                      onChange={handlePayments}
                      autoComplete="off"
                    />
                    <Error id={`${value}`} />
                  </div>
                );
              })}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4 text-black text-medium">Total</td>
            <td className="border p-4 text-black text-medium" colSpan={2}>
              {totals?.total > 0 ? totals?.total?.toFixed(3) : "0.000"}
            </td>
          </tr>
        </tfoot>
      </table>
      {/* <div className="flex justify-end p-3">
        <AddButton onClick={handleAdd} />
      </div> */}
    </div>
  );
});

export default Invoicetable;
