import React, { useEffect, useState } from "react";
import Header from "../../../Componets/Header";
import { useLocation } from "react-router-dom";
import products from "../../../Data/products.json";
import productsold from "../../../Data/productsold.json";
import {
  get_all_invoices,
  get_all_invoices_details,
} from "../../../Controllers/Sales/Invoices";
import toast from "react-hot-toast";
import Loadercomponent from "../../../Componets/Loader";

const ProductsoldReport = () => {
  let location = useLocation();

  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();

  const headers = {};

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const data = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        unit: tabledata?.unit,
        category: tabledata?.category,
        brand: tabledata?.brand,
        type: tabledata?.type,
        status: tabledata?.status,
        page: tabledata?.currentPage,
      };

      const products = await get_all_invoices_details(data);
      if (products?.status) {
        setBody(products?.data);
        setLoader(false);
      } else {
        toast?.error(products?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <table className="w-full bg-white">
          <thead className="text-gray-500">
            <tr>
              <td className="border p-4">Name</td>
              <td className="border p-4">Purchase</td>
              <td className="border p-4">Quantity</td>
              <td className="border p-4">Price/unit</td>
              <td className="border p-4">Sale</td>
              <td className="border p-4">Tax</td>
              <td className="border p-4">Amount</td>
            </tr>
          </thead>
          <tbody>
            {body?.map((value, index) => {
              return (
                <tr>
                  <td className="border p-4">{value?.name}</td>
                  <td className="border p-4">{value?.purchase_price}</td>
                  <td className="border p-4">{value?.quantity}</td>
                  <td className="border p-4">{value?.price_per_unit}</td>
                  <td className="border p-4">{value?.sale_price}</td>
                  <td className="border p-4">{value?.tax_amount}</td>
                  <td className="border p-4">{value?.total}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <td className="border p-4"></td>
            <td className="border p-4"></td>
            <td className="border p-4"></td>
            <td className="border p-4"></td>
            <td className="border p-4"></td>
            <td className="border p-4"></td>
            <td className="border p-4">
              {body
                ?.reduce(
                  (acc, currentValue) =>
                    parseFloat(acc) + parseFloat(currentValue?.total),
                  0
                )
                ?.toFixed(3)}
            </td>
          </tfoot>
        </table>
      </Loadercomponent>
    </Header>
  );
};

export default ProductsoldReport;
