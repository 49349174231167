import { PrinterOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";
import moment from "moment";
import { get_invoice } from "../../../../Controllers/Sales/Invoices";
import toast from "react-hot-toast";
import Loadercomponent from "../../../../Componets/Loader";

const InvoiceView = () => {
  let componentRef = useRef();

  let { id } = useParams();
  const [loader, setLoader] = useState(false);

  const [details, setDetails] = useState();

  const [companyName, setCompanyName] = useState("For Company");

  const injectGlobalStyles = () => {
    const style = document.createElement("style");
    style.innerHTML = `
      @page {
        size: A4;
        margin: 6% 6% 16% 6%;
         @bottom-left {
        font-size: 12px;
       content: "\\A \\A \\A \\A Customer's Signature (Name/Designation/Co. Seal) \\A \\A \\A";
        white-space: pre;
        position: fixed;
        bottom: 10mm;
        left: 6%;
      }
      @bottom-right {
        font-size: 12px;
          content: "\\A \\A \\A \\A For ${companyName} \\A \\A " counter(page) " of " counter(pages);
        white-space: pre;
        position: fixed;
        bottom: 10mm;
        right: 6%;
      }
      }
    `;
    document.head.appendChild(style);
  };

  const fetchData = async () => {
    setLoader(true);

    let data = {
      id: id,
    };

    let invoice = await get_invoice(data);
    if (invoice?.status) {
      setDetails(invoice?.data);
      setCompanyName(invoice?.data?.branch?.name);
      setLoader(false);
    } else {
      toast?.error(invoice?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
    if (companyName) {
      injectGlobalStyles(companyName);
    }
  }, [companyName]);

  return (
    <div>
      {/* page */}
      <Loadercomponent loader={loader}>
        <div className="flex justify-center">
          <div className="w-[8.27in]  text-right py-4">
            <ReactToPrint
              trigger={() => (
                <div className="">
                  <PrinterOutlined className="text-2xl" />
                </div>
              )}
              content={() => componentRef}
            />
          </div>
        </div>
        <div className="flex justify-center">
          <div className="page-a4" ref={(el) => (componentRef = el)}>
            {/*letter header */}
            <div className="text-black-900 text-[0.65rem] text-center">
              <div className="text-center">
                <div className="text-medium text-xl">
                  {details?.branch?.name}
                </div>
                <div className="text-medium">{details?.branch?.category}</div>
              </div>
            </div>
            <div className="text-black-900 text-[0.65rem] text-center pt-4">
              {/* <span>C.R.&nbsp;:&nbsp;1233305,&nbsp;</span>
            <span>P.O&nbsp;Box&nbsp;:&nbsp;5678,&nbsp;</span>
            <span>P.O&nbsp;Code&nbsp;:&nbsp;342,&nbsp;</span>
            <span>Fax&nbsp;:&nbsp;2132435,&nbsp;</span>
            <span>Tel&nbsp;:&nbsp;22876756,&nbsp;</span> */}
              <span>
                Email&nbsp;:&nbsp;
                {details?.branch?.email ? details?.branch?.email + ", " : ""}
                &nbsp;
              </span>
              <span>
                Phone&nbsp;:&nbsp;
                {details?.branch?.phone ? details?.branch?.phone : ""}
                &nbsp;
              </span>
              {/* <span>F.Code&nbsp;:&nbsp;8756432,&nbsp;</span> */}
              <div>
                {details?.branch?.area ? details?.branch?.area + ", " : ""}
                {details?.branch?.city ? details?.branch?.city + ", " : ""}
                {details?.branch?.country ? details?.branch?.country : ""}
              </div>
              <div>VATIN&nbsp;:&nbsp;N/A</div>
            </div>
            {/* heading */}
            <hr className="my-4 border-t-black-900 border-black" />
            <div className="text-medium text-xl text-center">Tax Invoice</div>
            <div className="text-xs pb-6">
              <div className="flex justify-between">
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td className="font-semibold text-sm">
                          {details?.supplier?.name}
                        </td>
                      </tr>
                      <tr>
                        <td> {details?.supplier?.area}</td>
                      </tr>
                      <tr>
                        <td>
                          {details?.supplier?.city
                            ? details?.supplier?.city + ", "
                            : " "}
                          {details?.supplier?.state}
                        </td>
                      </tr>
                      <tr>
                        <td>{details?.supplier?.country}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td>Invoice No</td>
                        <td className="px-2">:</td>
                        <td className="text-right">#INV-{details?.number}</td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td className="px-2">:</td>
                        <td className="text-right">
                          {moment?.(details?.date)?.format?.("DD-MM-YY")}
                        </td>
                      </tr>
                      <tr>
                        <td>Due</td>
                        <td className="px-2">:</td>
                        <td className="text-right">
                          {moment?.(details?.due_date)?.format?.("DD-MM-YY")}
                        </td>
                      </tr>
                      <tr>
                        <td>Reference</td>
                        <td className="px-2">:</td>
                        <td className="text-right">N/A</td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td className="px-2">:</td>
                        <td className="text-right">N/A</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* table */}
            <div className="">
              <table className="w-full">
                <thead className="bg-black">
                  <tr className="text-sm">
                    <th className="font-normal  text-white  text-left py-2 pl-3">
                      S.No
                    </th>
                    <th className="font-normal text-white text-left p-2">
                      Description
                    </th>
                    <th className="font-normal text-white text-center p-2">
                      Units
                    </th>
                    <th className="font-normal text-white text-center p-2">
                      Price
                    </th>
                    <th className="font-normal text-white text-center p-2">
                      Quantity
                    </th>
                    <th className="font-normal text-white text-center">Tax</th>
                    <th className="font-normal text-white text-right pr-2">
                      Total
                    </th>
                  </tr>
                </thead>
                {/* table body */}
                <tbody className="text-sm">
                  {details?.details?.map((value, index) => {
                    return (
                      <tr className="uppercase border-b border-gray-300 ">
                        <td className="py-1 text-center">{index + 1}</td>
                        <td className="py-1 text-left">
                          <div className="">{value?.name}</div>
                          <div className="text-gray-500">
                            {value?.expiry_date
                              ? `Expiry : ${moment?.(
                                  value?.expiry_date
                                )?.format("DD-MM-YY")}`
                              : ""}
                          </div>
                        </td>
                        <td className="py-1 text-center">{value?.unit_name}</td>
                        <td className="py-1 text-center">
                          {value?.sale_price?.toFixed(3)}
                        </td>
                        <td className="py-1 text-center">
                          {value?.quantity?.toFixed(3)}
                        </td>
                        <td className="py-1 text-center">
                          {value?.tax}%
                          <div>{value?.tax_amount?.toFixed(3)}</div>
                        </td>
                        <td className="text-right pr-2">
                          {value?.total?.toFixed(3)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                {/* table foot */}
                <tfoot className="text-sm ">
                  <tr className="">
                    <th colSpan={5}></th>
                    <th className="font-normal pt-1 text-right p-2">Total</th>
                    <td className="font-normal text-right p-2">
                      {details?.subtotal?.toFixed(3)}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={5}></th>
                    <th className="font-normal text-right p-2">VAT (5%)</th>
                    <td className="font-normal text-right p-2">
                      {details?.taxamount?.toFixed(3)}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={5}></th>
                    <th className="font-normal text-right p-2">Discount</th>
                    <td className="font-normal text-right p-2">
                      {details?.discount
                        ? "-" + details?.discount?.toFixed(3)
                        : "0.000"}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={5}></th>
                    <th className="font-normal text-right p-2">Delivery</th>
                    <td className="font-normal text-right p-2">
                      {details?.delivery
                        ? "+" + details?.delivery?.toFixed(3)
                        : "0.000"}
                    </td>
                  </tr>
                  <tr>
                    <th className="" colSpan={5}></th>
                    <th className="font-normal text-right text-white bg-black p-2">
                      Grand&nbsp;Total&nbsp;(OMR)
                    </th>
                    <td className="font-normal text-right text-white bg-black p-2">
                      {details?.total?.toFixed(3)}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            {/* note */}
            <div className="pt-4 text-sm">
              <div className="">
                {/* <div className="font-semibold">Note&nbsp;&nbsp;</div>
              <li>
                Please feel free to contact us for any questions or concerns.
              </li> */}
              </div>
              <div className="pt-4">
                {/* <div className="font-semibold">
                Terms & Conditions&nbsp;&nbsp;
              </div>
              <li>Quote validity for 30 days.</li> */}
              </div>
            </div>
            {/* <div className="">
              <div
                className="page-footer mb-[1.5cm]"
                style={{ padding: " 1.6cm" }}
              >
                <div className="flex justify-between px-[0.5cm]">
                  <div className="text-[0.65rem]">
                    Customer's Signature (Name/Designation/Co. Seal)
                  </div>
                  <div className="text-[0.65rem]">For New Pharmacy LLC</div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Loadercomponent>
    </div>
  );
};

export default InvoiceView;
